import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { Container, Typography, Stack, Box, Button, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';
import AuthContext from '../../../context/authContext';
import ProfilForm from '../../../sections/@dashboard/pegawai/forms/ProfilForm';
import pegawaiFormModel from '../../../sections/@dashboard/pegawai/form-model/pegawaiFormModel';
import validationSchema from '../../../sections/@dashboard/pegawai/form-model/validationSchema';

const ProfilPegawaiPage = ({ id, handleCloseModal, handleUpdateTable }) => {
  const notificationCtx = useContext(NotificationContext);
  const { user } = useContext(AuthContext);

  const [pageLoading, setPageLoading] = useState(true);
  const [dropdownData, setDropdownData] = useState({
    provinsis: [],
    kotaKabs: [],
    kecamatans: [],
    kotaKabDomis: [],
    kecamatanDomis: []
  });
  const [initialValues, setInitialValues] = useState({});
  const [selectedProvinsi, setSelectedProvinsi] = useState();
  const [selectedProvinsiDomi, setSelectedProvinsiDomi] = useState();
  const { formId, formField } = pegawaiFormModel;

  // Fetch dropdown data based on the selected ID
  const fetchData = async (endpoint, key, value) => {
    try {
      const url = value ? `/region/${endpoint}/${value}` : `/region/${endpoint}`;
      const { data } = await axiosPrivate.get(url);
      setDropdownData(prev => ({ ...prev, [key]: data }));
      return data;
    } catch (error) {
      console.error(`Failed to fetch data from ${endpoint}:`, error);
      return [];
    }
  };

  const handleSubmit = async (values, actions) => {
    const params = {
      kodePegawai: values.kode,
      idAnggota: values.idAnggota,
      nama: values.nama,
      namaTanpaGelar: values.namaTanpaGelar,
      jenisKelamin: values.jenisKelamin,
      tempatLahir: values.tempatLahir,
      tglLahir: moment(values.tglLahir).format('YYYY-MM-DD'),
      statusPerkawinan: values.statusPerkawinan,
      pendidikan: values.pendidikan,
      agama: values.agama,
      ukuranBaju: values.ukuranBaju,
      provinsi: values.provinsi,
      kecamatan: values.kecamatan,
      kotaKabupaten: values.kotaKab,
      alamat: values.alamat,
      domisili: values.useDomisili,
      provinsiDomisili: values.provinsiDomisili,
      kecamatanDomisili: values.kecamatanDomisili,
      kotaKabupatenDomisili: values.kotaKabDomisili,
      alamatDomisili: values.alamatDomisili,
      dibuatOleh: user._id,
      tglUbah: moment().format('YYYY-MM-DDTHH:mm:ss'),
    };

    try {
      const response = await axiosPrivate.put(`/pegawai/update-partial/${id}`, params);
      handleUpdateTable(response.data.result);
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: 'Data successfully updated.',
      });
    } catch (error) {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: error.response?.data?.message || 'Failed to update data.',
      });
      console.error('Update error:', error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleSetKotaKabs = async (provinceKey) => {
    try {
      const kotaKabs = await fetchData('kabupaten-kota', 'kotaKabs', provinceKey);
      setDropdownData(prev => ({
        ...prev,
        kotaKabs,
        kecamatans: [] // Clear kecamatans when province changes
      }));
      setSelectedProvinsi(provinceKey);
    } catch (error) {
      console.error('Error updating kotaKabs:', error);
    }
  };

  const handleSetKecamatans = async (cityKey) => {
    try {
      const kecamatans = await fetchData('kecamatan', 'kecamatans', `${selectedProvinsi}/${cityKey}`);
      setDropdownData(prev => ({
        ...prev,
        kecamatans
      }));
    } catch (error) {
      console.error('Error updating kecamatans:', error);
    }
  };
  
  const handleSetKotaKabDomis = async (provinceKey) => {
    try {
      const kotaKabDomis = await fetchData('kabupaten-kota', 'kotaKabDomis', provinceKey);
      setDropdownData(prev => ({
        ...prev,
        kotaKabDomis,
        kecamatanDomis: [] // Clear kecamatanDomis when domisili province changes
      }));
      setSelectedProvinsiDomi(provinceKey);
    } catch (error) {
      console.error('Error updating kotaKabDomis:', error);
    }
  };
  
  const handleSetKecamatanDomis = async (cityKey) => {
    try {
      const kecamatanDomis = await fetchData('kecamatan', 'kecamatanDomis', `${selectedProvinsiDomi}/${cityKey}`);
      setDropdownData(prev => ({
        ...prev,
        kecamatanDomis
      }));
    } catch (error) {
      console.error('Error updating kecamatanDomis:', error);
    }
  };  

  useEffect(() => {
    const loadInitialData = async () => {
      setPageLoading(true);
      try {
        const initialData = await axiosPrivate.get(`/pegawai/${id}`).then(res => res.data);
        setInitialValues({
          kode: initialData?.kodePegawai,
          idAnggota: initialData?.idAnggota,
          nama: initialData?.nama,
          namaTanpaGelar: initialData?.namaTanpaGelar,
          jenisKelamin: initialData?.jenisKelamin,
          tempatLahir: initialData?.tempatLahir,
          tglLahir: moment(initialData?.tglLahir).format('YYYY-MM-DD'),
          statusPerkawinan: initialData?.statusPerkawinan,
          pendidikan: initialData?.pendidikan,
          agama: initialData?.agama,
          ukuranBaju: initialData?.ukuranBaju,
          provinsi: initialData?.provinsi,
          kecamatan: initialData?.kecamatan,
          kotaKab: initialData?.kotaKabupaten,
          alamat: initialData?.alamat,
          domisili: initialData?.domisili,
          provinsiDomisili: initialData?.provinsiDomisili,
          kecamatanDomisili: initialData?.kecamatanDomisili,
          kotaKabDomisili: initialData?.kotaKabupatenDomisili,
          alamatDomisili: initialData?.alamatDomisili,
          useDomisili: initialData?.domisili
        });

        const provinces = await fetchData('provinsi', 'provinsis');
        const selectedProvinsi = provinces.find(p => p.value === initialData.provinsi);
        const selectedProvinsiDomis = provinces.find(p => p.value === initialData.provinsiDomisili);
        
        if (selectedProvinsi) {
          const cities = await fetchData(`kabupaten-kota/${selectedProvinsi.key}`, 'kotaKabs');
          const selectedCity = cities.find(c => c.value === initialData.kotaKabupaten);          

          if (selectedCity) {
            await fetchData(`kecamatan/${selectedProvinsi.key}/${selectedCity.key}`, 'kecamatans');
          }

          setSelectedProvinsi(selectedProvinsi.key);
        }

        if (selectedProvinsiDomis) {
          const cities = await fetchData(`kabupaten-kota/${selectedProvinsiDomis.key}`, 'kotaKabDomis');
          const selectedCityDomis = cities.find(c => c.value === initialData.kotaKabupatenDomisili);          

          if (selectedCityDomis) {
            await fetchData(`kecamatan/${selectedProvinsiDomis.key}/${selectedCityDomis.key}`, 'kecamatanDomis');
          }

          setSelectedProvinsiDomi(selectedProvinsiDomis.key);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setPageLoading(false);
      }
    };

    loadInitialData();
  }, [id]);

  return (
    <>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Edit Profile
            </Typography>
          </Stack>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema[0]}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                <ProfilForm
                  formField={formField}
                  dropDown={dropdownData}
                  values={initialValues}
                  provinsis={dropdownData.provinsis}
                  kotaKabs={dropdownData.kotaKabs}
                  kecamatans={dropdownData.kecamatans}
                  kotaKabDomis={dropdownData.kotaKabDomis}
                  kecamatanDomis={dropdownData.kecamatanDomis}
                  handleSetKotaKabs={handleSetKotaKabs}
                  handleSetKecamatans={handleSetKecamatans}
                  handleSetKotaKabDomis={handleSetKotaKabDomis}
                  handleSetKecamatanDomis={handleSetKecamatanDomis}
                />
                <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
                  <Button variant="contained" color="error" onClick={handleCloseModal}>
                    Back
                  </Button>
                  <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                    Save
                  </LoadingButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </Container>
      )}
    </>
  );
};

export default ProfilPegawaiPage;
