import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Box,
  IconButton
} from '@mui/material';
import { noCase } from 'change-case';
import Iconify from '../../components/iconify';
import { axiosPrivate } from '../../common/axiosPrivate';
import { fToNow } from '../../utils/formatTime';
import AuthContext from '../../context/authContext';

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  if (notification.type === 'contract_expiration_summary') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_contract_expiration.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}

function NotificationItem({ notification, onMarkAsRead }) {
  const { avatar, title } = renderContent(notification);

  const navigate = useNavigate();

  const handleClick = () => {
    onMarkAsRead(notification._id); // Call the function passed via props to mark as read
    navigate(notification.link); // Navigate to the link after marking as read
    // setPopoVerOpen(false);
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={handleClick}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

function NotificationPage() {
  const [notifications, setNotifications] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axiosPrivate.get(`notifications/${user?._id}`);
        setNotifications(response.data);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };

    fetchNotifications();
  }, [user?._id]);

  const markNotificationAsRead = async (notificationId) => {
    try {
      await axiosPrivate.patch(`notifications/${notificationId}/mark-as-read`);
      const updatedNotifications = notifications.map(notification =>
        notification._id === notificationId ? { ...notification, isUnRead: false } : notification
      );
      setNotifications(updatedNotifications);
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  return (
    <Box sx={{ p: 3 }}> {/* Adding padding around the entire content */}
      <IconButton onClick={handleBack} sx={{ mb: 2 }}>
        <Iconify icon="mdi:arrow-left" width={24} height={24} />
      </IconButton>
      <Typography variant="h4" sx={{ mb: 2 }}>All Notifications</Typography>
      <div>
        {notifications.map((notification) => (
          <NotificationItem 
            key={notification._id} 
            notification={notification} 
            onMarkAsRead={markNotificationAsRead} 
          />
        ))}
      </div>
    </Box>
  );
}

export default NotificationPage;
